import React from 'react';
import { useCookies } from '../Context/CookiesContext';
import {
  Dialog, DialogTitle, DialogContent, DialogActions,
  Button, IconButton, Typography, styled, Slide, Box
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import CookieIcon from '@mui/icons-material/Cookie';

const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiPaper-root': {
        backgroundColor: '#f5f5f5', // Gris claro para el fondo del modal
        margin: 0,
        position: 'fixed',
        bottom: 0,
        borderRadius: theme.shape.borderRadius,
    },
    '& .MuiDialogTitle-root': {
        backgroundColor: '#FFA500', // Naranja claro
        color: '#ffffff', // Texto blanco
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(2),
    },
    '& .MuiDialogContent-root': {
        backgroundColor: '#f5f5f5', // Gris claro
        color: 'black', // Texto negro
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        backgroundColor: '#f5f5f5', // Gris claro
        padding: theme.spacing(2),
    },
}));
const CookieModal = () => {
  const { setCookie, isFirstVisit, cookiesAccepted } = useCookies(); // Asegúrate de que useCookies esté siendo usado correctamente

  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    if (isFirstVisit && !cookiesAccepted) {
      setOpen(true);
    }
  }, [isFirstVisit, cookiesAccepted]);

  const handleAcceptCookies = () => {
    setCookie('firstVisit', 'false', { expires: 7 });
    setCookie('cookiesAccepted', 'true', { expires: 365 });
    setOpen(false);
  };

  const handleRejectCookies = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}>
      <StyledDialog open={open} onClose={handleRejectCookies}>
        <DialogTitle>
          <CookieIcon sx={{ fontSize: 24 }} />
          <Typography variant="h6">Política de Cookies</Typography>
          <IconButton onClick={handleRejectCookies} sx={{ color: '#fff' }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography gutterBottom>
            Este sitio web utiliza cookies para mejorar la experiencia del usuario.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAcceptCookies}>Aceptar</Button>
          <Button onClick={handleRejectCookies}>Rechazar</Button>
        </DialogActions>
      </StyledDialog>
    </Box>
  );
};

export default CookieModal;
